/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DefinePreferredPharmacyRequest } from '../models/DefinePreferredPharmacyRequest';
import type { FindPharmaciesQuery } from '../models/FindPharmaciesQuery';
import type { SuccessCollectionPharmacyDetailsResponse } from '../models/SuccessCollectionPharmacyDetailsResponse';
import type { SuccessListPharmacyDetailsResponse } from '../models/SuccessListPharmacyDetailsResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PharmaciesControllerService {

    /**
     * Returns a list of current user's preferred pharmacyId in given provider
     * @returns SuccessCollectionPharmacyDetailsResponse OK
     * @throws ApiError
     */
    public static getCurrentUserPreferredPharmacies(): CancelablePromise<SuccessCollectionPharmacyDetailsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/pharmacies/users/me/preferred',
            errors: {
                400: `Bad Request`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
                500: `An unexpected error has occurred.`,
            },
        });
    }

    /**
     * Define a preferred pharmacy for current user.
     * @param requestBody
     * @returns string OK
     * @throws ApiError
     */
    public static defineCurrentUserPreferredPharmacy(
        requestBody: DefinePreferredPharmacyRequest,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/pharmacies/users/me/preferred',
            body: requestBody,
            mediaType: 'application/json',
            responseHeader: 'X-NineamHealth-TraceId',
            errors: {
                400: `Bad Request`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
                500: `An unexpected error has occurred.`,
            },
        });
    }

    /**
     * Search for pharmacies based on multiple query params.
     * @param query
     * @param name
     * @param city
     * @param state
     * @param zip
     * @param address
     * @param phoneOrFax
     * @param specialty
     * @param ncpdpId
     * @returns SuccessListPharmacyDetailsResponse OK
     * @throws ApiError
     */
    public static userSearchPharmacies(
        query: FindPharmaciesQuery,
        name?: string,
        city?: string,
        state?: string,
        zip?: string,
        address?: string,
        phoneOrFax?: string,
        specialty?: Array<'EPCS' | 'TWENTY_FOUR_HOUR_PHARMACY' | 'LONG_TERM_CARE_PHARMACY' | 'MAIL_ORDER' | 'RETAIL' | 'SPECIALTY_PHARMACY'>,
        ncpdpId?: string,
    ): CancelablePromise<SuccessListPharmacyDetailsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/pharmacies/users/me/search',
            query: {
                'name': name,
                'city': city,
                'state': state,
                'zip': zip,
                'address': address,
                'phoneOrFax': phoneOrFax,
                'specialty': specialty,
                'ncpdpID': ncpdpId,
                'query': query,
            },
            errors: {
                400: `Bad Request`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
                500: `An unexpected error has occurred.`,
            },
        });
    }

}
