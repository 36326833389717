/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SetPreferencesRequest } from '../models/SetPreferencesRequest';
import type { SuccessUserPreferencesResponse } from '../models/SuccessUserPreferencesResponse';
import type { UpdatePreferencesRequest } from '../models/UpdatePreferencesRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserPreferencesControllerService {

    /**
     * Return's a user's preferences.
     * Returns all preferences of the requesting user in the form of key-value pairs.
     * @returns SuccessUserPreferencesResponse All user preferences.
     * @throws ApiError
     */
    public static getPreferences(): CancelablePromise<SuccessUserPreferencesResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/users/me/preferences',
            errors: {
                400: `Bad Request`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
                500: `An unexpected error has occurred.`,
            },
        });
    }

    /**
     * Sets a user's preferences.
     * Sets a user's preferences by overwriting all existing preferences with the provided ones. Any  preference that is not included in the request body will be deleted.
     * @param requestBody
     * @returns SuccessUserPreferencesResponse The preferences were saved.
     * @throws ApiError
     */
    public static setPreferences(
        requestBody: SetPreferencesRequest,
    ): CancelablePromise<SuccessUserPreferencesResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/users/me/preferences',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
                500: `An unexpected error has occurred.`,
            },
        });
    }

    /**
     * Updates a user's preferences.
     * Sets a user's preferences by overwriting all existing preferences with the provided ones. Any preference that is not included in the body will remain unchanged.
     * @param requestBody
     * @returns SuccessUserPreferencesResponse The preferences were updated.
     * @throws ApiError
     */
    public static updatePreferences(
        requestBody: UpdatePreferencesRequest,
    ): CancelablePromise<SuccessUserPreferencesResponse> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v1/users/me/preferences',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
                500: `An unexpected error has occurred.`,
            },
        });
    }

}
