/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Data that is needed to trigger lab order request.
 */
export type TriggerLabOrderRequest = {
    /**
     * The lab order ID.
     */
    labOrderId: string;
    /**
     * The lab order provider type
     */
    labOrderProviderType: TriggerLabOrderRequest.labOrderProviderType;
};

export namespace TriggerLabOrderRequest {

    /**
     * The lab order provider type
     */
    export enum labOrderProviderType {
        TRUEPILL = 'TRUEPILL',
        BIO_REFERENCE_LAB = 'BIO_REFERENCE_LAB',
        PATIENT = 'PATIENT',
        ZENDESK = 'ZENDESK',
        GETLABS = 'GETLABS',
        QUEST = 'QUEST',
        LABCORP = 'LABCORP',
        KWIK_TRIP_CENTER_FOR_HEALTH = 'KWIK_TRIP_CENTER_FOR_HEALTH',
        EVERLYWELL = 'EVERLYWELL',
        TASSO = 'TASSO',
    }


}

