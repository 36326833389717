/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { MedicationAbatement } from './MedicationAbatement';
import type { MedicationDailyDosage } from './MedicationDailyDosage';
import type { Source } from './Source';

/**
 * Collection of medications
 */
export type MedicationsResponseItem = {
    source: Source;
    /**
     * Date when the information was entered in the Profile
     */
    recordedAt: any;
    /**
     * The name of the medication
     */
    name?: string;
    /**
     * Collection of categories for the medication
     */
    categories: Array<'DIABETES' | 'DIABETES_SGLT2' | 'DIABETES_GLP1' | 'DIABETES_DPP4' | 'INSULIN' | 'SHORT_ACTING_INSULIN' | 'LONG_ACTING_INSULIN' | 'RAPID_ACTING_INSULIN' | 'MIXED_INSULIN' | 'INTERMEDIATE_SHORT_ACTING_INSULIN' | 'INTERMEDIATE_ACTING_INSULIN' | 'INTERMEDIATE_RAPID_ACTING_INSULIN' | 'BLOOD_PRESSURE' | 'CHOLESTEROL' | 'HEART_FAILURE' | 'NEPRILYSIN_INHIBITOR' | 'CYP3_INHIBITOR' | 'PROTEASE_INHIBITOR' | 'ANTI_FUNGAL' | 'SPIRONOLACTONE' | 'HEPATITIS' | 'KINASE_INHIBITOR' | 'CLARITHROMYCIN_OR_ERYHROMYCIN' | 'HIV_OR_TUBERCULOSIS' | 'WELCHOL_COLESTID_CHOLESTYRAMINE' | 'SUPPLEMENTS' | 'WEIGHT_LOSS' | 'OTHER' | 'THYROID' | 'ERECTILE_DYSFUNCTION' | 'CONTRACEPTIVE' | 'NICOTINE_REPLACEMENT' | 'ANTIDEPRESSANT' | 'ANTICONVULSANT' | 'OPIOID_ANTAGONIST'>;
    /**
     * The medication
     */
    medication?: MedicationsResponseItem.medication;
    /**
     * The medication item
     */
    medicationItem?: MedicationsResponseItem.medicationItem;
    dailyDosage?: MedicationDailyDosage;
    /**
     * The onset of the medication intake
     */
    onset?: any;
    abatement?: MedicationAbatement;
};

export namespace MedicationsResponseItem {

    /**
     * The medication
     */
    export enum medication {
        METFORMIN = 'METFORMIN',
        METFORMIN_ER = 'METFORMIN_ER',
        GLIPIZIDE = 'GLIPIZIDE',
        GLIPIZIDE_XL = 'GLIPIZIDE_XL',
        GLIMEPIRIDE = 'GLIMEPIRIDE',
        PIOGLITAZONE = 'PIOGLITAZONE',
        METAGLIP = 'METAGLIP',
        LISINOPRIL = 'LISINOPRIL',
        LOSARTAN = 'LOSARTAN',
        HYDROCHLOROTHIAZIDE = 'HYDROCHLOROTHIAZIDE',
        AMLODIPINE = 'AMLODIPINE',
        METOPROLOL_TARTRATE_LOPRESSOR = 'METOPROLOL_TARTRATE_LOPRESSOR',
        METOPROLOL_SUCCINATE_TOPROL_XL = 'METOPROLOL_SUCCINATE_TOPROL_XL',
        LOVAZA = 'LOVAZA',
        GEMFIBROZIL = 'GEMFIBROZIL',
        ATORVASTATIN = 'ATORVASTATIN',
        ROSUVASTATIN = 'ROSUVASTATIN',
        LOVASTATIN = 'LOVASTATIN',
        SIMVASTATIN = 'SIMVASTATIN',
        PRAVASTATIN = 'PRAVASTATIN',
        EZETIMIBE = 'EZETIMIBE',
        FENOFIBRATE = 'FENOFIBRATE',
        RED_YEAST_RICE = 'RED_YEAST_RICE',
        EXENATIDE = 'EXENATIDE',
        LIRAGLUTIDE = 'LIRAGLUTIDE',
        DULAGLUTIDE = 'DULAGLUTIDE',
        LIXISENATIDE = 'LIXISENATIDE',
        SEMAGLUTIDE = 'SEMAGLUTIDE',
        SEMAGLUTIDE_WEGOVY = 'SEMAGLUTIDE_WEGOVY',
        SEMAGLUTIDE_RYBELSUS = 'SEMAGLUTIDE_RYBELSUS',
        TIRZEPATIDE_MOUNJARO = 'TIRZEPATIDE_MOUNJARO',
        TIRZEPATIDE_ZEPBOUND = 'TIRZEPATIDE_ZEPBOUND',
        RYBELSUS = 'RYBELSUS',
        SYNJARDY = 'SYNJARDY',
        SYNJARDY_XR = 'SYNJARDY_XR',
        CANAGLIFLOZIN = 'CANAGLIFLOZIN',
        DAPAGLIFLOZIN = 'DAPAGLIFLOZIN',
        EMPAGLIFLOZIN = 'EMPAGLIFLOZIN',
        ERTUGLIFLOZIN = 'ERTUGLIFLOZIN',
        BEXAGLIFLOZIN = 'BEXAGLIFLOZIN',
        JANUMET = 'JANUMET',
        JANUMET_XR = 'JANUMET_XR',
        SITAGLIPITIN = 'SITAGLIPITIN',
        SITAGLIPTIN_METFORMIN = 'SITAGLIPTIN_METFORMIN',
        SAXAGLIPTIN = 'SAXAGLIPTIN',
        LINAGLIPTIN = 'LINAGLIPTIN',
        ALOGLIPTIN = 'ALOGLIPTIN',
        INSULIN = 'INSULIN',
        LANTUS = 'LANTUS',
        BASAGLAR = 'BASAGLAR',
        SEMGLEE = 'SEMGLEE',
        TOUJEO = 'TOUJEO',
        LEVEMIR = 'LEVEMIR',
        TRESIBA = 'TRESIBA',
        HUMULIN = 'HUMULIN',
        NOVOLIN = 'NOVOLIN',
        HUMALOG = 'HUMALOG',
        NOVOLOG = 'NOVOLOG',
        APIDRA = 'APIDRA',
        HUMULIN_R = 'HUMULIN_R',
        NOVOLIN_R = 'NOVOLIN_R',
        CONTRAVE = 'CONTRAVE',
        PHENTERMINE = 'PHENTERMINE',
        QSYMIA = 'QSYMIA',
        TOPAMAX = 'TOPAMAX',
        WELLBUTRIN = 'WELLBUTRIN',
        XENICAL = 'XENICAL',
        LENIOLISIB = 'LENIOLISIB',
        UNKNOWN = 'UNKNOWN',
    }

    /**
     * The medication item
     */
    export enum medicationItem {
        METFORMIN_500 = 'METFORMIN_500',
        METFORMIN_850 = 'METFORMIN_850',
        METFORMIN_1000 = 'METFORMIN_1000',
        METFORMIN_ER_500 = 'METFORMIN_ER_500',
        METFORMIN_ER_750 = 'METFORMIN_ER_750',
        METFORMIN_ER_1000 = 'METFORMIN_ER_1000',
        METAGLIP_2_5_250 = 'METAGLIP_2_5_250',
        METAGLIP_2_5_500 = 'METAGLIP_2_5_500',
        METAGLIP_5_500 = 'METAGLIP_5_500',
        GLIPIZIDE_5 = 'GLIPIZIDE_5',
        GLIPIZIDE_10 = 'GLIPIZIDE_10',
        GLIPIZIDE_XL_2_5 = 'GLIPIZIDE_XL_2_5',
        GLIPIZIDE_XL_5 = 'GLIPIZIDE_XL_5',
        GLIPIZIDE_XL_10 = 'GLIPIZIDE_XL_10',
        GLIMEPIRIDE_1 = 'GLIMEPIRIDE_1',
        GLIMEPIRIDE_2 = 'GLIMEPIRIDE_2',
        GLIMEPIRIDE_4 = 'GLIMEPIRIDE_4',
        PIOGLITAZONE_15 = 'PIOGLITAZONE_15',
        PIOGLITAZONE_30 = 'PIOGLITAZONE_30',
        PIOGLITAZONE_45 = 'PIOGLITAZONE_45',
        LISINOPRIL_2_5 = 'LISINOPRIL_2_5',
        LISINOPRIL_5 = 'LISINOPRIL_5',
        LISINOPRIL_10 = 'LISINOPRIL_10',
        LISINOPRIL_20 = 'LISINOPRIL_20',
        LISINOPRIL_30 = 'LISINOPRIL_30',
        LISINOPRIL_40 = 'LISINOPRIL_40',
        LOSARTAN_25 = 'LOSARTAN_25',
        LOSARTAN_50 = 'LOSARTAN_50',
        LOSARTAN_100 = 'LOSARTAN_100',
        HYDROCHLOROTHIAZIDE_12_5 = 'HYDROCHLOROTHIAZIDE_12_5',
        HYDROCHLOROTHIAZIDE_25 = 'HYDROCHLOROTHIAZIDE_25',
        HYDROCHLOROTHIAZIDE_50 = 'HYDROCHLOROTHIAZIDE_50',
        AMLODIPINE_2_5 = 'AMLODIPINE_2_5',
        AMLODIPINE_5 = 'AMLODIPINE_5',
        AMLODIPINE_10 = 'AMLODIPINE_10',
        METOPROLOL_TARTRATE_LOPRESSOR_25 = 'METOPROLOL_TARTRATE_LOPRESSOR_25',
        METOPROLOL_TARTRATE_LOPRESSOR_37_5 = 'METOPROLOL_TARTRATE_LOPRESSOR_37_5',
        METOPROLOL_TARTRATE_LOPRESSOR_50 = 'METOPROLOL_TARTRATE_LOPRESSOR_50',
        METOPROLOL_TARTRATE_LOPRESSOR_75 = 'METOPROLOL_TARTRATE_LOPRESSOR_75',
        METOPROLOL_TARTRATE_LOPRESSOR_100 = 'METOPROLOL_TARTRATE_LOPRESSOR_100',
        METOPROLOL_SUCCINATE_TOPROL_XL_25 = 'METOPROLOL_SUCCINATE_TOPROL_XL_25',
        METOPROLOL_SUCCINATE_TOPROL_XL_50 = 'METOPROLOL_SUCCINATE_TOPROL_XL_50',
        METOPROLOL_SUCCINATE_TOPROL_XL_100 = 'METOPROLOL_SUCCINATE_TOPROL_XL_100',
        METOPROLOL_SUCCINATE_TOPROL_XL_200 = 'METOPROLOL_SUCCINATE_TOPROL_XL_200',
        LOVAZA_1000 = 'LOVAZA_1000',
        GEMFIBROZIL_600 = 'GEMFIBROZIL_600',
        ATORVASTATIN_10 = 'ATORVASTATIN_10',
        ATORVASTATIN_20 = 'ATORVASTATIN_20',
        ATORVASTATIN_40 = 'ATORVASTATIN_40',
        ATORVASTATIN_80 = 'ATORVASTATIN_80',
        ROSUVASTATIN_5 = 'ROSUVASTATIN_5',
        ROSUVASTATIN_10 = 'ROSUVASTATIN_10',
        ROSUVASTATIN_20 = 'ROSUVASTATIN_20',
        ROSUVASTATIN_40 = 'ROSUVASTATIN_40',
        LOVASTATIN_10 = 'LOVASTATIN_10',
        LOVASTATIN_20 = 'LOVASTATIN_20',
        LOVASTATIN_40 = 'LOVASTATIN_40',
        SIMVASTATIN_5 = 'SIMVASTATIN_5',
        SIMVASTATIN_10 = 'SIMVASTATIN_10',
        SIMVASTATIN_20 = 'SIMVASTATIN_20',
        SIMVASTATIN_40 = 'SIMVASTATIN_40',
        SIMVASTATIN_80 = 'SIMVASTATIN_80',
        PRAVASTATIN_10 = 'PRAVASTATIN_10',
        PRAVASTATIN_20 = 'PRAVASTATIN_20',
        PRAVASTATIN_40 = 'PRAVASTATIN_40',
        PRAVASTATIN_80 = 'PRAVASTATIN_80',
        EZETIMIBE_10 = 'EZETIMIBE_10',
        FENOFIBRATE_48 = 'FENOFIBRATE_48',
        FENOFIBRATE_54 = 'FENOFIBRATE_54',
        FENOFIBRATE_134 = 'FENOFIBRATE_134',
        FENOFIBRATE_145 = 'FENOFIBRATE_145',
        FENOFIBRATE_160 = 'FENOFIBRATE_160',
        TRULICITY_DULAGLUTIDE_0_75 = 'TRULICITY_DULAGLUTIDE_0_75',
        TRULICITY_DULAGLUTIDE_1_5 = 'TRULICITY_DULAGLUTIDE_1_5',
        TRULICITY_DULAGLUTIDE_3 = 'TRULICITY_DULAGLUTIDE_3',
        TRULICITY_DULAGLUTIDE_4_5 = 'TRULICITY_DULAGLUTIDE_4_5',
        OZEMPIC_SEMAGLUTIDE_0_5 = 'OZEMPIC_SEMAGLUTIDE_0_5',
        OZEMPIC_SEMAGLUTIDE_1_0 = 'OZEMPIC_SEMAGLUTIDE_1_0',
        OZEMPIC_SEMAGLUTIDE_2_0 = 'OZEMPIC_SEMAGLUTIDE_2_0',
        BYDUREON_EXENATIDE_2 = 'BYDUREON_EXENATIDE_2',
        VICTOZA_LIRAGLUTIDE_18_3 = 'VICTOZA_LIRAGLUTIDE_18_3',
        WEGOVY_SEMAGLUTIDE_0_25 = 'WEGOVY_SEMAGLUTIDE_0_25',
        WEGOVY_SEMAGLUTIDE_0_5 = 'WEGOVY_SEMAGLUTIDE_0_5',
        WEGOVY_SEMAGLUTIDE_1_0 = 'WEGOVY_SEMAGLUTIDE_1_0',
        WEGOVY_SEMAGLUTIDE_1_7 = 'WEGOVY_SEMAGLUTIDE_1_7',
        WEGOVY_SEMAGLUTIDE_2_4 = 'WEGOVY_SEMAGLUTIDE_2_4',
        MOUNJARO_TIRZEPATIDE_2_5 = 'MOUNJARO_TIRZEPATIDE_2_5',
        MOUNJARO_TIRZEPATIDE_5 = 'MOUNJARO_TIRZEPATIDE_5',
        MOUNJARO_TIRZEPATIDE_7_5 = 'MOUNJARO_TIRZEPATIDE_7_5',
        MOUNJARO_TIRZEPATIDE_10 = 'MOUNJARO_TIRZEPATIDE_10',
        MOUNJARO_TIRZEPATIDE_12_5 = 'MOUNJARO_TIRZEPATIDE_12_5',
        MOUNJARO_TIRZEPATIDE_15 = 'MOUNJARO_TIRZEPATIDE_15',
        RYBELSUS_SEMAGLUTIDE_ORAL_3 = 'RYBELSUS_SEMAGLUTIDE_ORAL_3',
        RYBELSUS_SEMAGLUTIDE_ORAL_7 = 'RYBELSUS_SEMAGLUTIDE_ORAL_7',
        RYBELSUS_SEMAGLUTIDE_ORAL_14 = 'RYBELSUS_SEMAGLUTIDE_ORAL_14',
        ZEPBOUND_TIRZEPATIDE_2_5 = 'ZEPBOUND_TIRZEPATIDE_2_5',
        ZEPBOUND_TIRZEPATIDE_5 = 'ZEPBOUND_TIRZEPATIDE_5',
        ZEPBOUND_TIRZEPATIDE_7_5 = 'ZEPBOUND_TIRZEPATIDE_7_5',
        ZEPBOUND_TIRZEPATIDE_10 = 'ZEPBOUND_TIRZEPATIDE_10',
        ZEPBOUND_TIRZEPATIDE_12_5 = 'ZEPBOUND_TIRZEPATIDE_12_5',
        ZEPBOUND_TIRZEPATIDE_15 = 'ZEPBOUND_TIRZEPATIDE_15',
        INVOKANA_CANAGLIFLOZIN_100 = 'INVOKANA_CANAGLIFLOZIN_100',
        INVOKANA_CANAGLIFLOZIN_300 = 'INVOKANA_CANAGLIFLOZIN_300',
        FARXIGA_DAPAGLIFLOZIN_5 = 'FARXIGA_DAPAGLIFLOZIN_5',
        FARXIGA_DAPAGLIFLOZIN_10 = 'FARXIGA_DAPAGLIFLOZIN_10',
        JARDIANCE_EMPAGLIFLOZIN_10 = 'JARDIANCE_EMPAGLIFLOZIN_10',
        JARDIANCE_EMPAGLIFLOZIN_25 = 'JARDIANCE_EMPAGLIFLOZIN_25',
        STEGLATRO_ERTUGLIFLOZIN_5 = 'STEGLATRO_ERTUGLIFLOZIN_5',
        STEGLATRO_ERTUGLIFLOZIN_15 = 'STEGLATRO_ERTUGLIFLOZIN_15',
        BRENZAVVY_BEXAGLIFLOZIN_20 = 'BRENZAVVY_BEXAGLIFLOZIN_20',
        JANUVIA_SITAGLIPTIN_25 = 'JANUVIA_SITAGLIPTIN_25',
        JANUVIA_SITAGLIPTIN_50 = 'JANUVIA_SITAGLIPTIN_50',
        JANUVIA_SITAGLIPTIN_100 = 'JANUVIA_SITAGLIPTIN_100',
        ONGLYZA_SAXAGLIPTIN_2_5 = 'ONGLYZA_SAXAGLIPTIN_2_5',
        ONGLYZA_SAXAGLIPTIN_5 = 'ONGLYZA_SAXAGLIPTIN_5',
        TRAJENTA_LINAGLIPTIN_5 = 'TRAJENTA_LINAGLIPTIN_5',
        NESINA_ALOGLIPTIN_6_25 = 'NESINA_ALOGLIPTIN_6_25',
        NESINA_ALOGLIPTIN_12_5 = 'NESINA_ALOGLIPTIN_12_5',
        NESINA_ALOGLIPTIN_25 = 'NESINA_ALOGLIPTIN_25',
        SYNJARDY_5_METFORMIN_500 = 'SYNJARDY_5_METFORMIN_500',
        SYNJARDY_5_METFORMIN_1000 = 'SYNJARDY_5_METFORMIN_1000',
        SYNJARDY_12_5_METFORMIN_500 = 'SYNJARDY_12_5_METFORMIN_500',
        SYNJARDY_12_5_METFORMIN_1000 = 'SYNJARDY_12_5_METFORMIN_1000',
        SYNJARDY_XR_5_METFORMIN_1000 = 'SYNJARDY_XR_5_METFORMIN_1000',
        SYNJARDY_XR_10_METFORMIN_1000 = 'SYNJARDY_XR_10_METFORMIN_1000',
        SYNJARDY_XR_12_5_METFORMIN_1000 = 'SYNJARDY_XR_12_5_METFORMIN_1000',
        SYNJARDY_XR_25_METFORMIN_1000 = 'SYNJARDY_XR_25_METFORMIN_1000',
        JANUMET_50_METFORMIN_500 = 'JANUMET_50_METFORMIN_500',
        JANUMET_50_METFORMIN_1000 = 'JANUMET_50_METFORMIN_1000',
        JANUMET_XR_50_METFORMIN_500 = 'JANUMET_XR_50_METFORMIN_500',
        JANUMET_XR_50_METFORMIN_1000 = 'JANUMET_XR_50_METFORMIN_1000',
        JANUMET_XR_100_METFORMIN_1000 = 'JANUMET_XR_100_METFORMIN_1000',
        INSULIN = 'INSULIN',
        LANTUS_GLARGINE = 'LANTUS_GLARGINE',
        BASAGLAR_GLARGINE = 'BASAGLAR_GLARGINE',
        SEMGLEE_GLARGINE = 'SEMGLEE_GLARGINE',
        TOUJEO_U300_GLARGINE = 'TOUJEO_U300_GLARGINE',
        LEVEMIR_DETEMIR = 'LEVEMIR_DETEMIR',
        TRESIBA_DEGLUDEC = 'TRESIBA_DEGLUDEC',
        HUMULIN_N_NPH = 'HUMULIN_N_NPH',
        NOVOLIN_N_NPH = 'NOVOLIN_N_NPH',
        HUMALOG_LISPRO = 'HUMALOG_LISPRO',
        NOVOLOG_ASPART = 'NOVOLOG_ASPART',
        APIDRA_GLULISINE = 'APIDRA_GLULISINE',
        HUMULIN_R_REGULAR = 'HUMULIN_R_REGULAR',
        NOVOLIN_R_REGULAR = 'NOVOLIN_R_REGULAR',
        LIXISENATIDE = 'LIXISENATIDE',
        RYBELSUS = 'RYBELSUS',
    }


}

