/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ActivateMfaCredentialRequest } from '../models/ActivateMfaCredentialRequest';
import type { RemoveMfaCredentialsRequest } from '../models/RemoveMfaCredentialsRequest';
import type { SuccessRetrieveMfaCredentialResponse } from '../models/SuccessRetrieveMfaCredentialResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserMfaControllerService {

    /**
     * Registers a MFA secret for the user.
     * This operation stores the MFA secret for the requesting user, enabling themto log in using it. Registration is only possible if the secret conforms to the expected format,and if a valid 6-digit token is provided.
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static activateCredential(
        requestBody: ActivateMfaCredentialRequest,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/users/me/mfa',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `The provided secret does not match the expected format.`,
                401: `The provided token is invalid.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
                500: `An unexpected error has occurred.`,
            },
        });
    }

    /**
     * Removes a MFA secret for the requesting user.
     * This operation removes the stored MFA credentials for the requesting user. To be successful, a validMFA token has to be provided as part of the request.
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static removeCredential(
        requestBody: RemoveMfaCredentialsRequest,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/users/me/mfa',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `The provided token is invalid.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
                500: `An unexpected error has occurred.`,
            },
        });
    }

    /**
     * Returns a suitable MFA secret.
     * This operation returns a MFA secret that can subsequentially be used to generate a token andregister the secret using the `registerCredential` operation.
     * @param type The type of secret
     * @returns SuccessRetrieveMfaCredentialResponse A MFA secret.
     * @throws ApiError
     */
    public static retrieveCredential(
        type: 'RFC6238',
    ): CancelablePromise<SuccessRetrieveMfaCredentialResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/users/me/mfa/{type}',
            path: {
                'type': type,
            },
            errors: {
                400: `Bad Request`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
                500: `An unexpected error has occurred.`,
            },
        });
    }

}
