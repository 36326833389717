/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { LabOrderProviderSpecificsAdminResponse } from './LabOrderProviderSpecificsAdminResponse';
import type { LabOrderStatusHistoryItemResponse } from './LabOrderStatusHistoryItemResponse';
import type { LabOrderTestAdminResponse } from './LabOrderTestAdminResponse';

/**
 * Lab orders information
 */
export type LabOrderAdminResponse = {
    /**
     * The lab order unique ID.
     */
    labOrderId: string;
    /**
     * The provider type
     */
    providerType: LabOrderAdminResponse.providerType;
    /**
     * The status of given lab order
     */
    status: LabOrderAdminResponse.status;
    /**
     * The last update date for given lab order
     */
    lastUpdated: string;
    /**
     * The scheduled date for the lab order
     */
    triggerAt: string;
    tests: Array<LabOrderTestAdminResponse>;
    /**
     * The provider unique ID.
     */
    providerId?: string;
    providerSpecifics?: LabOrderProviderSpecificsAdminResponse;
    /**
     * Lab status history items.
     */
    statusHistoryItems?: Array<LabOrderStatusHistoryItemResponse>;
    /**
     * The id of the lab results file.
     */
    labResultsFileId?: string;
};

export namespace LabOrderAdminResponse {

    /**
     * The provider type
     */
    export enum providerType {
        TRUEPILL = 'TRUEPILL',
        BIO_REFERENCE_LAB = 'BIO_REFERENCE_LAB',
        PATIENT = 'PATIENT',
        ZENDESK = 'ZENDESK',
        GETLABS = 'GETLABS',
        QUEST = 'QUEST',
        LABCORP = 'LABCORP',
        KWIK_TRIP_CENTER_FOR_HEALTH = 'KWIK_TRIP_CENTER_FOR_HEALTH',
        EVERLYWELL = 'EVERLYWELL',
        TASSO = 'TASSO',
    }

    /**
     * The status of given lab order
     */
    export enum status {
        ORDERED = 'ORDERED',
        PENDING = 'PENDING',
        WAITING_FOR_LAB_REQUISITION_UPLOAD = 'WAITING_FOR_LAB_REQUISITION_UPLOAD',
        PROCESSING = 'PROCESSING',
        CANCELED = 'CANCELED',
        DECLINED = 'DECLINED',
        SHIPMENT_INITIATED = 'SHIPMENT_INITIATED',
        WAITING_FOR_PATIENT_TO_SCHEDULE_APPOINTMENT = 'WAITING_FOR_PATIENT_TO_SCHEDULE_APPOINTMENT',
        WAITING_FOR_PATIENT_TO_RESCHEDULE_APPOINTMENT = 'WAITING_FOR_PATIENT_TO_RESCHEDULE_APPOINTMENT',
        APPOINTMENT_SCHEDULED = 'APPOINTMENT_SCHEDULED',
        SHIPPING_TO_PATIENT = 'SHIPPING_TO_PATIENT',
        DELIVERED_TO_PATIENT = 'DELIVERED_TO_PATIENT',
        KIT_SENT_TO_LAB = 'KIT_SENT_TO_LAB',
        KIT_DELIVERED_TO_LAB = 'KIT_DELIVERED_TO_LAB',
        SPECIMENS_COLLECTED = 'SPECIMENS_COLLECTED',
        RESULTS_AVAILABLE = 'RESULTS_AVAILABLE',
        COMPLETED = 'COMPLETED',
    }


}

