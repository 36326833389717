/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * The Prescriber info.
 */
export type ProviderDto = {
    id?: string;
    userId?: string;
    lastUpdated?: string;
    npi?: string;
    npiLastUpdated?: string;
    firstName?: string;
    lastName?: string;
    credential?: ProviderDto.credential;
    displayName?: string;
    displayNameWithRole?: string;
    primaryTaxonomy?: string;
    providerAvailabilities?: Array<'STATE_LICENSE_AA' | 'STATE_LICENSE_AE' | 'STATE_LICENSE_AL' | 'STATE_LICENSE_AK' | 'STATE_LICENSE_AP' | 'STATE_LICENSE_AS' | 'STATE_LICENSE_AZ' | 'STATE_LICENSE_AR' | 'STATE_LICENSE_CA' | 'STATE_LICENSE_CO' | 'STATE_LICENSE_CT' | 'STATE_LICENSE_DE' | 'STATE_LICENSE_DC' | 'STATE_LICENSE_FM' | 'STATE_LICENSE_FL' | 'STATE_LICENSE_GA' | 'STATE_LICENSE_GU' | 'STATE_LICENSE_HI' | 'STATE_LICENSE_ID' | 'STATE_LICENSE_IL' | 'STATE_LICENSE_IN' | 'STATE_LICENSE_IA' | 'STATE_LICENSE_KS' | 'STATE_LICENSE_KY' | 'STATE_LICENSE_LA' | 'STATE_LICENSE_ME' | 'STATE_LICENSE_MD' | 'STATE_LICENSE_MH' | 'STATE_LICENSE_MA' | 'STATE_LICENSE_MI' | 'STATE_LICENSE_MN' | 'STATE_LICENSE_MS' | 'STATE_LICENSE_MO' | 'STATE_LICENSE_MT' | 'STATE_LICENSE_MP' | 'STATE_LICENSE_NE' | 'STATE_LICENSE_NV' | 'STATE_LICENSE_NH' | 'STATE_LICENSE_NJ' | 'STATE_LICENSE_NM' | 'STATE_LICENSE_NY' | 'STATE_LICENSE_NC' | 'STATE_LICENSE_ND' | 'STATE_LICENSE_OH' | 'STATE_LICENSE_OK' | 'STATE_LICENSE_OR' | 'STATE_LICENSE_PW' | 'STATE_LICENSE_PA' | 'STATE_LICENSE_PR' | 'STATE_LICENSE_RI' | 'STATE_LICENSE_SC' | 'STATE_LICENSE_SD' | 'STATE_LICENSE_TN' | 'STATE_LICENSE_TX' | 'STATE_LICENSE_UT' | 'STATE_LICENSE_VT' | 'STATE_LICENSE_VI' | 'STATE_LICENSE_VA' | 'STATE_LICENSE_WA' | 'STATE_LICENSE_WV' | 'STATE_LICENSE_WI' | 'STATE_LICENSE_WY' | 'CONSULTATION_FORMAT_SYNC' | 'CONSULTATION_FORMAT_ASYNC' | 'PARTNER_AMAZON' | 'PARTNER_APPLE' | 'PARTNER_OTHER_B2B' | 'PARTNER_DTC' | 'PARTNER_OTHER'>;
};

export namespace ProviderDto {

    export enum credential {
        UNKNOWN = 'UNKNOWN',
        DOCTOR_OF_MEDICINE = 'DOCTOR_OF_MEDICINE',
        DOCTOR_OF_OSTEOPATHIC_MEDICINE = 'DOCTOR_OF_OSTEOPATHIC_MEDICINE',
        NURSE_PRACTITIONER = 'NURSE_PRACTITIONER',
        PHYSICIAN_ASSISTANT = 'PHYSICIAN_ASSISTANT',
    }


}

