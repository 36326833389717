/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AddDiagnosisCarePlanActionRequest } from './AddDiagnosisCarePlanActionRequest';
import type { AddMedicationCarePlanActionRequest } from './AddMedicationCarePlanActionRequest';
import type { AddServiceRequestCarePlanActionRequest } from './AddServiceRequestCarePlanActionRequest';
import type { AddSupplyCarePlanActionRequest } from './AddSupplyCarePlanActionRequest';
import type { AdjustMedicationCarePlanActionRequest } from './AdjustMedicationCarePlanActionRequest';
import type { AdjustSupplyCarePlanActionRequest } from './AdjustSupplyCarePlanActionRequest';
import type { AnswerQuestionCarePlanActionRequest } from './AnswerQuestionCarePlanActionRequest';
import type { CarePlanActionRequest } from './CarePlanActionRequest';
import type { ContinueDiagnosisCarePlanActionRequest } from './ContinueDiagnosisCarePlanActionRequest';
import type { ContinueMedicationCarePlanActionRequest } from './ContinueMedicationCarePlanActionRequest';
import type { ContinueSupplyCarePlanActionRequest } from './ContinueSupplyCarePlanActionRequest';
import type { DocumentMedicationCarePlanActionRequest } from './DocumentMedicationCarePlanActionRequest';
import type { RenewMedicationCarePlanActionRequest } from './RenewMedicationCarePlanActionRequest';
import type { RenewSupplyCarePlanActionRequest } from './RenewSupplyCarePlanActionRequest';
import type { StopDiagnosisCarePlanActionRequest } from './StopDiagnosisCarePlanActionRequest';
import type { StopMedicationPrescribingCarePlanActionRequest } from './StopMedicationPrescribingCarePlanActionRequest';
import type { StopSupplyCarePlanActionRequest } from './StopSupplyCarePlanActionRequest';
import type { TakeConsultationNotesCarePlanActionRequest } from './TakeConsultationNotesCarePlanActionRequest';

/**
 * Data that is needed to modify treatment plan
 */
export type CreateConsultationRequest = {
    /**
     * The patient ID
     */
    patientId?: string;
    /**
     * The summary of reason to create consult
     */
    reasonSummary?: string;
    /**
     * The consultation format
     */
    format: CreateConsultationRequest.format;
    /**
     * Determines whether draft consultation should be created or not
     */
    draft?: boolean;
    /**
     * Recommended care plan actions.
     */
    carePlanActions?: Array<(CarePlanActionRequest | AddDiagnosisCarePlanActionRequest | AddMedicationCarePlanActionRequest | AddServiceRequestCarePlanActionRequest | AddSupplyCarePlanActionRequest | AdjustMedicationCarePlanActionRequest | AdjustSupplyCarePlanActionRequest | AnswerQuestionCarePlanActionRequest | ContinueDiagnosisCarePlanActionRequest | ContinueMedicationCarePlanActionRequest | ContinueSupplyCarePlanActionRequest | DocumentMedicationCarePlanActionRequest | RenewMedicationCarePlanActionRequest | RenewSupplyCarePlanActionRequest | StopDiagnosisCarePlanActionRequest | StopMedicationPrescribingCarePlanActionRequest | StopSupplyCarePlanActionRequest | TakeConsultationNotesCarePlanActionRequest)>;
};

export namespace CreateConsultationRequest {

    /**
     * The consultation format
     */
    export enum format {
        ASYNC = 'ASYNC',
        SYNC = 'SYNC',
    }


}

