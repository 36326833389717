/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddAnswerToSessionRequest } from '../models/AddAnswerToSessionRequest';
import type { StartSessionRequest } from '../models/StartSessionRequest';
import type { SubmitAnswersDataRequest } from '../models/SubmitAnswersDataRequest';
import type { SuccessJsonNode } from '../models/SuccessJsonNode';
import type { SuccessListAnsweredQuestionnaire } from '../models/SuccessListAnsweredQuestionnaire';
import type { SuccessStartSessionResponse } from '../models/SuccessStartSessionResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class QuestionnaireControllerService {

    /**
     * Starts an answering session for a questionnaire.
     * @param requestBody
     * @returns SuccessStartSessionResponse OK
     * @throws ApiError
     */
    public static startSession(
        requestBody: StartSessionRequest,
    ): CancelablePromise<SuccessStartSessionResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/questionnaire/sessions',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
                500: `An unexpected error has occurred.`,
            },
        });
    }

    /**
     * Adds an answer to an existing session.
     * @param sessionId
     * @param requestBody
     * @returns string OK
     * @throws ApiError
     */
    public static addAnswerToSession(
        sessionId: string,
        requestBody: AddAnswerToSessionRequest,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/questionnaire/sessions/{sessionId}/answers',
            path: {
                'sessionId': sessionId,
            },
            body: requestBody,
            mediaType: 'application/json',
            responseHeader: 'X-NineamHealth-TraceId',
            errors: {
                400: `Bad Request`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
                500: `An unexpected error has occurred.`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns string OK
     * @throws ApiError
     */
    public static answerWithData(
        requestBody: SubmitAnswersDataRequest,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/questionnaire/answers/data',
            body: requestBody,
            mediaType: 'application/json',
            responseHeader: 'X-NineamHealth-TraceId',
            errors: {
                400: `Bad Request`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
                500: `An unexpected error has occurred.`,
            },
        });
    }

    /**
     * Returns the content of a questionnaire.
     * Returns the full specification of a questionnaire. The format depends on the `type`.
     * @param type
     * @param id
     * @param cache Whether to use the local version (true) or load directly from the original source (false).
     * @param language Overrides the language in which the questionnaire is returned (falls back to the user's preferred language).
     * @returns SuccessJsonNode OK
     * @throws ApiError
     */
    public static getQuestionnaire(
        type: 'TYPEFORM' | 'NINEAM_HEALTH',
        id: string,
        cache: boolean = true,
        language?: any,
    ): CancelablePromise<SuccessJsonNode> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/questionnaire/{type}/{id}',
            path: {
                'type': type,
                'id': id,
            },
            query: {
                'cache': cache,
                'language': language,
            },
            errors: {
                400: `Bad Request`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The questionnaire was not found.`,
                500: `An unexpected error has occurred.`,
            },
        });
    }

    /**
     * Returns all answered questionnaires.
     * Returns all questionnaires that were answered by the requesting user.
     * @returns SuccessListAnsweredQuestionnaire OK
     * @throws ApiError
     */
    public static getAnsweredQuestionnaires(): CancelablePromise<SuccessListAnsweredQuestionnaire> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/questionnaire/answered',
            errors: {
                400: `Bad Request`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
                500: `An unexpected error has occurred.`,
            },
        });
    }

}
