/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Reason = {
    identifier: Reason.identifier;
    description: string;
};

export namespace Reason {

    export enum identifier {
        STATE = 'STATE',
        NAME = 'NAME',
        DATE_OF_BIRTH = 'DATE_OF_BIRTH',
        ANONYMOUS = 'ANONYMOUS',
        A1C_MISSING = 'A1C_MISSING',
        A1C_ORDERED_NO_RESULT = 'A1C_ORDERED_NO_RESULT',
        SEX = 'SEX',
        PREGNANT_OR_BREASTFEEDING = 'PREGNANT_OR_BREASTFEEDING',
        MEDICAL_CONDITIONS = 'MEDICAL_CONDITIONS',
        MEDICATIONS = 'MEDICATIONS',
        MEDICAL_SYMPTOMS = 'MEDICAL_SYMPTOMS',
        BLOOD_PRESSURE_MISSING = 'BLOOD_PRESSURE_MISSING',
        MEDICARE = 'MEDICARE',
        CARE_TEAM_CALL = 'CARE_TEAM_CALL',
        ETHNICITIES = 'ETHNICITIES',
        BMI = 'BMI',
    }


}

