/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AdminFileResponse } from './AdminFileResponse';

/**
 * Represents medication prior authorization status history item data .
 */
export type MedicationPriorAuthorizationStatusHistoryItemResponse = {
    /**
     * Timestamp of status history for the PA. Deprecated, use statusTimestamp instead.
     * @deprecated
     */
    updatedAt: string;
    /**
     * Status for the PA in history
     */
    status: MedicationPriorAuthorizationStatusHistoryItemResponse.status;
    /**
     * Timestamp when the PA status got effective
     */
    statusTimestamp: string;
    /**
     * Status description for the PA
     */
    description?: string;
    /**
     * Status comment for the PA
     */
    comment?: string;
    /**
     * Documents related with status change of the PA
     */
    attachments?: Array<AdminFileResponse>;
};

export namespace MedicationPriorAuthorizationStatusHistoryItemResponse {

    /**
     * Status for the PA in history
     */
    export enum status {
        WAITING_ON_PHARMACY = 'WAITING_ON_PHARMACY',
        NEW = 'NEW',
        CREATED = 'CREATED',
        SENT_TO_PLAN = 'SENT_TO_PLAN',
        NOT_SENT_TO_PLAN = 'NOT_SENT_TO_PLAN',
        OUTCOME_UNKNOWN = 'OUTCOME_UNKNOWN',
        PATIENT_NOT_COVERED_BY_PLAN = 'PATIENT_NOT_COVERED_BY_PLAN',
        APPROVED = 'APPROVED',
        DENIED = 'DENIED',
    }


}

