/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Eligible = {
    treatmentTarget: string;
    eligibility: Eligible.eligibility;
};

export namespace Eligible {

    export enum eligibility {
        ELIGIBILE = 'ELIGIBILE',
        UNDECIDED = 'UNDECIDED',
        INELIGIBLE = 'INELIGIBLE',
        DATA_NEEDED = 'DATA_NEEDED',
    }


}

