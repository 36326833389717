/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DeregisterDeviceRequest } from '../models/DeregisterDeviceRequest';
import type { RegisterDeviceRequest } from '../models/RegisterDeviceRequest';
import type { SuccessCollectionDeviceItem } from '../models/SuccessCollectionDeviceItem';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DeviceControllerService {

    /**
     * Lists 9am-provided devices for the logged in user
     * @returns SuccessCollectionDeviceItem OK
     * @throws ApiError
     */
    public static findUserDevices(): CancelablePromise<SuccessCollectionDeviceItem> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/devices',
            errors: {
                400: `Bad Request`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
                500: `An unexpected error has occurred.`,
            },
        });
    }

    /**
     * Registers a new device with the current user.
     * @param requestBody
     * @returns string OK
     * @throws ApiError
     */
    public static registerDeviceByExternalId(
        requestBody: RegisterDeviceRequest,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/devices',
            body: requestBody,
            mediaType: 'application/json',
            responseHeader: 'X-NineamHealth-TraceId',
            errors: {
                400: `Bad Request`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
                500: `An unexpected error has occurred.`,
            },
        });
    }

    /**
     * De-registers a device with the current user.
     * @param requestBody
     * @returns string OK
     * @throws ApiError
     */
    public static deregisterDeviceByExternalId(
        requestBody: DeregisterDeviceRequest,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/devices',
            body: requestBody,
            mediaType: 'application/json',
            responseHeader: 'X-NineamHealth-TraceId',
            errors: {
                400: `Bad Request`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
                500: `An unexpected error has occurred.`,
            },
        });
    }

    /**
     * Sends data (observations) for a registered device.
     * @param requestBody
     * @returns string OK
     * @throws ApiError
     */
    public static saveDeviceObservations(
        requestBody: any,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/devices/observations',
            body: requestBody,
            responseHeader: 'X-NineamHealth-TraceId',
            errors: {
                400: `Bad Request`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
                500: `An unexpected error has occurred.`,
            },
        });
    }

}
