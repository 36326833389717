/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EventTrackingRequest } from '../models/EventTrackingRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AnalyticsControllerService {

    /**
     * Tracks an event for a given user
     * @param requestBody
     * @returns string OK
     * @throws ApiError
     */
    public static trackEvent(
        requestBody: EventTrackingRequest,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/analytics/track',
            body: requestBody,
            mediaType: 'application/json',
            responseHeader: 'X-NineamHealth-TraceId',
            errors: {
                400: `Bad Request`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
                500: `An unexpected error has occurred.`,
            },
        });
    }

}
