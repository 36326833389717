/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SuccessLoginResponse } from '../models/SuccessLoginResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserShortLivedTokenControllerService {

    /**
     * Retrieves a short-lived token for the current user session.
     * @returns SuccessLoginResponse OK
     * @throws ApiError
     */
    public static retrieveShortLivedToken(): CancelablePromise<SuccessLoginResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/users/me/tokens/shortlived',
            errors: {
                400: `Bad Request`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
                500: `An unexpected error has occurred.`,
            },
        });
    }

}
