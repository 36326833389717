/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UpdateLabOrderProviderRequest = {
    /**
     * The ID of the lab order
     */
    labOrderId: string;
    /**
     * The new lab order provider
     */
    newLabOrderProviderType: UpdateLabOrderProviderRequest.newLabOrderProviderType;
    /**
     * The reference id of the lab order for the new lab order provider
     */
    newProviderOrderReferenceId?: string;
};

export namespace UpdateLabOrderProviderRequest {

    /**
     * The new lab order provider
     */
    export enum newLabOrderProviderType {
        TRUEPILL = 'TRUEPILL',
        BIO_REFERENCE_LAB = 'BIO_REFERENCE_LAB',
        PATIENT = 'PATIENT',
        ZENDESK = 'ZENDESK',
        GETLABS = 'GETLABS',
        QUEST = 'QUEST',
        LABCORP = 'LABCORP',
        KWIK_TRIP_CENTER_FOR_HEALTH = 'KWIK_TRIP_CENTER_FOR_HEALTH',
        EVERLYWELL = 'EVERLYWELL',
        TASSO = 'TASSO',
    }


}

