/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { GuarantorResponse } from './GuarantorResponse';
import type { PayerAddressResponse } from './PayerAddressResponse';

/**
 * An optional object, providing the actual result.
 */
export type MedicalInsuranceResponse = {
    id: string;
    /**
     * CardHolder ID of an Insurance or a Manufacturer Coupon.
     */
    cardHolderId: string;
    /**
     * Status of current Insurance/Coupon.
     */
    status: MedicalInsuranceResponse.status;
    /**
     * The date when a insurance is effective from.
     */
    effectiveStartDate?: string;
    /**
     * The date when this insurance is effective until.
     */
    effectiveEndDate?: string;
    /**
     * ID of user who stored insurance/coupon details.
     */
    createdBy: string;
    /**
     * ID of user who modified insurance/coupon details.
     */
    updatedBy: string;
    /**
     * The date/time when this object was created.
     */
    createdAt: string;
    /**
     * The date/time when this object was updated.
     */
    lastUpdated: string;
    /**
     * The ID of uploaded file which is front of the card.
     */
    frontPhotoFileId?: string;
    /**
     * The ID of uploaded file which is back of the card.
     */
    backPhotoFileId?: string;
    groupNumber?: string;
    /**
     * The name of the insurance plan.
     */
    planName?: string;
    /**
     * Flag - if an insurance is a primary.
     */
    isPrimary: boolean;
    /**
     * Relationship to a cardholder.
     */
    primaryPlanOwner: MedicalInsuranceResponse.primaryPlanOwner;
    /**
     * The id of partner.
     */
    partnerId?: string;
    payer?: PayerAddressResponse;
    guarantor?: GuarantorResponse;
};

export namespace MedicalInsuranceResponse {

    /**
     * Status of current Insurance/Coupon.
     */
    export enum status {
        ACTIVE = 'ACTIVE',
        INACTIVE = 'INACTIVE',
    }

    /**
     * Relationship to a cardholder.
     */
    export enum primaryPlanOwner {
        SELF = 'SELF',
        SPOUSE = 'SPOUSE',
        PARENT = 'PARENT',
        OTHER = 'OTHER',
        UNKNOWN = 'UNKNOWN',
    }


}

