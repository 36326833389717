/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Undecided = {
    treatmentTarget: string;
    eligibility: Undecided.eligibility;
};

export namespace Undecided {

    export enum eligibility {
        ELIGIBILE = 'ELIGIBILE',
        UNDECIDED = 'UNDECIDED',
        INELIGIBLE = 'INELIGIBLE',
        DATA_NEEDED = 'DATA_NEEDED',
    }


}

