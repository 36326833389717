/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InitiateEmailVerificationRequest } from '../models/InitiateEmailVerificationRequest';
import type { SuccessEmailVerificationDto } from '../models/SuccessEmailVerificationDto';
import type { VerifyEmailRequest } from '../models/VerifyEmailRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserEmailVerificationControllerService {

    /**
     * Initiates an email verification procedure for an email address.
     * Sends an email to the specified email address, including a verification code and/or a link.The credentials provided in the link (or the combination of email address, token, and code) should be used with the /v1/users/email/verification/confirm endpoint to complete the verification.
     * @param requestBody
     * @returns SuccessEmailVerificationDto The verification was initiated and an email was sent.
     * @throws ApiError
     */
    public static initiateEmailVerification(
        requestBody: InitiateEmailVerificationRequest,
    ): CancelablePromise<SuccessEmailVerificationDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/users/email/verification/initiate',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
                500: `An unexpected error has occurred.`,
            },
        });
    }

    /**
     * Verifies an email address.
     * Verifies an email and therefore completes the user registration. For this to be successful,all elements (email, verification code, verification token) must be present, non-expired, and valid.
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static completeEmailVerification(
        requestBody: VerifyEmailRequest,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/users/email/verification/confirm',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
                500: `An unexpected error has occurred.`,
            },
        });
    }

}
