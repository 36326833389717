/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PopulateUserRequest } from '../models/PopulateUserRequest';
import type { SuccessPopulateUserResponse } from '../models/SuccessPopulateUserResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class LillyControllerService {

    /**
     * Populates the authenticated user's profile with data from the Lilly handover process.
     * @param requestBody
     * @returns SuccessPopulateUserResponse OK
     * @throws ApiError
     */
    public static populateUser(
        requestBody: PopulateUserRequest,
    ): CancelablePromise<SuccessPopulateUserResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/integrations/lilly/populate',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
                500: `An unexpected error has occurred.`,
            },
        });
    }

}
