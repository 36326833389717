/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MedicalInsuranceRequest } from '../models/MedicalInsuranceRequest';
import type { PharmacyCouponRequest } from '../models/PharmacyCouponRequest';
import type { PharmacyInsuranceRequest } from '../models/PharmacyInsuranceRequest';
import type { SuccessBenefitsListResponse } from '../models/SuccessBenefitsListResponse';
import type { SuccessMedicalInsuranceResponse } from '../models/SuccessMedicalInsuranceResponse';
import type { SuccessPharmacyCouponResponse } from '../models/SuccessPharmacyCouponResponse';
import type { SuccessPharmacyInsuranceResponse } from '../models/SuccessPharmacyInsuranceResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BenefitsControllerService {

    /**
     * Updates an existing pharmacy insurance.
     * @param id
     * @param requestBody
     * @returns SuccessPharmacyInsuranceResponse OK
     * @throws ApiError
     */
    public static updatePharmacyInsurance(
        id: string,
        requestBody: PharmacyInsuranceRequest,
    ): CancelablePromise<SuccessPharmacyInsuranceResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/benefits/pharmacy/insurances/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
                500: `An unexpected error has occurred.`,
            },
        });
    }

    /**
     * Updates an existing pharmacy coupon.
     * @param id
     * @param requestBody
     * @returns SuccessPharmacyCouponResponse OK
     * @throws ApiError
     */
    public static updatePharmacyCoupon(
        id: string,
        requestBody: PharmacyCouponRequest,
    ): CancelablePromise<SuccessPharmacyCouponResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/benefits/pharmacy/coupons/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
                500: `An unexpected error has occurred.`,
            },
        });
    }

    /**
     * Updates an existing medical insurance.
     * @param id
     * @param requestBody
     * @returns SuccessMedicalInsuranceResponse OK
     * @throws ApiError
     */
    public static updateMedicalInsurance(
        id: string,
        requestBody: MedicalInsuranceRequest,
    ): CancelablePromise<SuccessMedicalInsuranceResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/benefits/medical/insurances/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
                500: `An unexpected error has occurred.`,
            },
        });
    }

    /**
     * Creates a new pharmacy insurance.
     * @param requestBody
     * @returns SuccessPharmacyInsuranceResponse OK
     * @throws ApiError
     */
    public static createPharmacyInsurance(
        requestBody: PharmacyInsuranceRequest,
    ): CancelablePromise<SuccessPharmacyInsuranceResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/benefits/pharmacy/insurances',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
                500: `An unexpected error has occurred.`,
            },
        });
    }

    /**
     * Creates a new pharmacy coupon.
     * @param requestBody
     * @returns SuccessPharmacyCouponResponse OK
     * @throws ApiError
     */
    public static createPharmacyCoupon(
        requestBody: PharmacyCouponRequest,
    ): CancelablePromise<SuccessPharmacyCouponResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/benefits/pharmacy/coupons',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
                500: `An unexpected error has occurred.`,
            },
        });
    }

    /**
     * Creates a new medical insurance.
     * @param requestBody
     * @returns SuccessMedicalInsuranceResponse OK
     * @throws ApiError
     */
    public static createMedicalInsurance(
        requestBody: MedicalInsuranceRequest,
    ): CancelablePromise<SuccessMedicalInsuranceResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/benefits/medical/insurance',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
                500: `An unexpected error has occurred.`,
            },
        });
    }

    /**
     * Returns benefits information for the given user.
     * @returns SuccessBenefitsListResponse OK
     * @throws ApiError
     */
    public static getBenefits(): CancelablePromise<SuccessBenefitsListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/benefits',
            errors: {
                400: `Bad Request`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
                500: `An unexpected error has occurred.`,
            },
        });
    }

}
