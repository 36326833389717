/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Reason } from './Reason';

export type DataNeeded = {
    reasons: Array<Reason>;
    treatmentTarget: string;
    eligibility: DataNeeded.eligibility;
};

export namespace DataNeeded {

    export enum eligibility {
        ELIGIBILE = 'ELIGIBILE',
        UNDECIDED = 'UNDECIDED',
        INELIGIBLE = 'INELIGIBLE',
        DATA_NEEDED = 'DATA_NEEDED',
    }


}

