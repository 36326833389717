/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Information that needs to be provided in order for a user to store a coupon details.
 * @deprecated
 */
export type PharmacyCouponRequestOld = {
    /**
     * CardHolder ID of a Pharmacy Insurance or a Manufacturer Coupon.
     */
    cardHolderId: string;
    /**
     * Group Number of a Pharmacy Insurance or a Manufacturer Coupon.
     */
    rxGrp?: string;
    /**
     * Status of current Insurance/Coupon.
     */
    status: PharmacyCouponRequestOld.status;
    /**
     * The date when a insurance is effective from.
     */
    effectiveStartDate?: string;
    /**
     * The date when this insurance is effective until.
     */
    effectiveEndDate?: string;
    /**
     * The ID of uploaded file which is front of the card.
     */
    frontPhotoFileId?: string;
    /**
     * The ID of uploaded file which is back of the card.
     */
    backPhotoFileId?: string;
    /**
     * Bank Identification Number of a Pharmacy Insurance or a Manufacturer Coupon.
     */
    rxBin: string;
    /**
     * Processor Control Number of a Pharmacy Insurance or a Manufacturer Coupon.
     */
    rxPcn?: string;
    /**
     * The medication name covered fully or partially by a manufacturer coupon.
     */
    medicationName: string;
    /**
     * Phone Number of a Pharmacy Insurance or a Manufacturer.
     */
    customerServiceNumber?: string;
};

export namespace PharmacyCouponRequestOld {

    /**
     * Status of current Insurance/Coupon.
     */
    export enum status {
        ACTIVE = 'ACTIVE',
        INACTIVE = 'INACTIVE',
    }


}

