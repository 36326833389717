/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Represents an existing prospective appointment
 */
export type ProspectiveAppointmentResponse = {
    /**
     * The prospective appointment id.
     */
    id: string;
    /**
     * The user's ID.
     */
    userId: string;
    /**
     * The appointment type.
     */
    type: ProspectiveAppointmentResponse.type;
    /**
     * The status of the prospective appointment.
     */
    status: ProspectiveAppointmentResponse.status;
    /**
     * The title of the prospective appointment.
     */
    title: string;
};

export namespace ProspectiveAppointmentResponse {

    /**
     * The appointment type.
     */
    export enum type {
        PERSONAL = 'PERSONAL',
        ONBOARDING_SPECIALIST = 'ONBOARDING_SPECIALIST',
        SYNC_VISIT = 'SYNC_VISIT',
        COACHING_1ON1 = 'COACHING_1ON1',
        DIETITIAN_VISIT = 'DIETITIAN_VISIT',
        PHARMACIST_VISIT = 'PHARMACIST_VISIT',
        CCS_MED_CONSULT = 'CCS_MED_CONSULT',
        LAB_RESULTS_REVIEW = 'LAB_RESULTS_REVIEW',
        SPECIALIST_VISIT = 'SPECIALIST_VISIT',
        AT_HOME_LABS_GETLABS = 'AT_HOME_LABS_GETLABS',
    }

    /**
     * The status of the prospective appointment.
     */
    export enum status {
        PENDING = 'PENDING',
        COMPLETED = 'COMPLETED',
        CANCELED = 'CANCELED',
    }


}

