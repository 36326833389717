/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Represents a scheduling URL for a given appointment type.
 */
export type AppointmentSchedulingUrlResponse = {
    /**
     * The appointment type.
     */
    type: AppointmentSchedulingUrlResponse.type;
    /**
     * The title of the appointment.
     */
    title: string;
    /**
     * The description of the appointment.
     */
    description: string;
    /**
     * The scheduling URL.
     */
    schedulingUrl?: string;
    /**
     * Whether the appointment type is already planned/scheduled
     */
    inProgress: boolean;
};

export namespace AppointmentSchedulingUrlResponse {

    /**
     * The appointment type.
     */
    export enum type {
        PERSONAL = 'PERSONAL',
        ONBOARDING_SPECIALIST = 'ONBOARDING_SPECIALIST',
        SYNC_VISIT = 'SYNC_VISIT',
        COACHING_1ON1 = 'COACHING_1ON1',
        DIETITIAN_VISIT = 'DIETITIAN_VISIT',
        PHARMACIST_VISIT = 'PHARMACIST_VISIT',
        CCS_MED_CONSULT = 'CCS_MED_CONSULT',
        LAB_RESULTS_REVIEW = 'LAB_RESULTS_REVIEW',
        SPECIALIST_VISIT = 'SPECIALIST_VISIT',
        AT_HOME_LABS_GETLABS = 'AT_HOME_LABS_GETLABS',
    }


}

