/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Creates a prospective appointment
 */
export type CreateProspectiveAppointmentRequest = {
    /**
     * The type of appointment that should be created
     */
    type: CreateProspectiveAppointmentRequest.type;
};

export namespace CreateProspectiveAppointmentRequest {

    /**
     * The type of appointment that should be created
     */
    export enum type {
        PERSONAL = 'PERSONAL',
        ONBOARDING_SPECIALIST = 'ONBOARDING_SPECIALIST',
        SYNC_VISIT = 'SYNC_VISIT',
        COACHING_1ON1 = 'COACHING_1ON1',
        DIETITIAN_VISIT = 'DIETITIAN_VISIT',
        PHARMACIST_VISIT = 'PHARMACIST_VISIT',
        CCS_MED_CONSULT = 'CCS_MED_CONSULT',
        LAB_RESULTS_REVIEW = 'LAB_RESULTS_REVIEW',
        SPECIALIST_VISIT = 'SPECIALIST_VISIT',
        AT_HOME_LABS_GETLABS = 'AT_HOME_LABS_GETLABS',
    }


}

